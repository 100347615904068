import color from 'atoms/colors/colors';
import shadow from 'atoms/shadows/shadows';
import { padding } from 'atoms/spacing/spacing';
import { fontSize, fontWeight } from 'atoms/typography/typography';

declare module '@mui/material/Button' {
  interface ButtonPropsSizeOverrides {
    sm: true;
    xl: true;
  }
}

const themeOverrides = {
  root: {
    minWidth: 0,
    fontWeight: fontWeight.bold,
    borderRadius: '80px',
    fontSize: fontSize.textSm,
    boxShadow: shadow.xs,
    textTransform: 'uppercase',
  },
};

const textVariantStyles = {
  backgroundColor: 'transparent',
  padding: 0,
  boxShadow: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
};

const sizeVariants = [
  {
    props: { size: 'sm' },
    style: {
      lineHeight: '20px',
      paddingTop: padding[8],
      paddingBottom: padding[8],
      paddingLeft: '21px',
      paddingRight: '21px',
    },
  },
  {
    props: { size: 'md' },
    style: {
      lineHeight: '20px',
      paddingLeft: padding[20],
      paddingRight: padding[20],
    },
  },
  {
    props: { size: 'lg' },
    style: {
      fontSize: fontSize.textMd,
      lineHeight: '24px',
      paddingLeft: padding[24],
      paddingRight: padding[24],
    },
  },
  {
    props: { size: 'xl' },
    style: {
      fontSize: fontSize.textMd,
      lineHeight: '24px',
      paddingTop: padding[12],
      paddingBottom: padding[12],
      paddingLeft: padding[28],
      paddingRight: padding[28],
    },
  },
  {
    props: { size: 'xxl' },
    style: {
      fontSize: fontSize.textLg,
      lineHeight: '28px',
      paddingLeft: padding[28],
      paddingRight: padding[28],
    },
  },
];

const themeVariants = {
  MuiButton: {
    styleOverrides: themeOverrides,
    variants: [
      ...sizeVariants,
      {
        props: { variant: 'contained', color: 'primary' },
        style: {
          color: color.common.white,
          backgroundColor: color.primary[400],
          borderColor: color.primary[400],
          '&:hover, &.hovered': {
            backgroundColor: color.primary[700],
            borderColor: color.primary[700],
          },
          '&:focus, &.focused': {
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF;',
          },
          '&:disabled, &.disabled': {
            background: color.primary[50],
            borderColor: color.primary[50],
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'contained', color: 'secondary' },
        style: {
          color: color.common.white,
          backgroundColor: color.valenciaOrange[500],
          '&:hover, &.hovered': {
            backgroundColor: color.valenciaOrange[800],
          },
          '&:focus, &.focused': {
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
          },
          '&:disabled, &.disabled': {
            background: color.valenciaOrange[100],
            borderColor: color.valenciaOrange[100],
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'primary' },
        style: {
          color: color.primary[400],
          borderColor: color.primary[400],
          '&:hover, &.hovered': {
            backgroundColor: color.primary[400],
            borderColor: color.primary[400],
            color: color.common.white,
          },
          '&:focus, &.focused': {
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F2F4F7;',
          },
          '&:disabled, &.disabled': {
            borderColor: color.primary[100],
            color: color.primary[100],
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'secondary' },
        style: {
          color: color.valenciaOrange[500],
          borderColor: color.valenciaOrange[500],
          '&:hover, &.hovered': {
            backgroundColor: color.valenciaOrange[500],
            borderColor: color.valenciaOrange[500],
            color: color.common.white,
          },
          '&:focus, &.focused': {
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
          },
          '&:disabled, &.disabled': {
            borderColor: color.common.transparent,
            color: color.gray[300],
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'outlined', color: 'inherit' },
        style: {
          color: color.common.white,
          borderColor: color.common.white,
          '&:hover, &.hovered': {
            backgroundColor: color.common.white,
            borderColor: color.common.white,
            color: color.primary[400],
          },
          '&:focus, &.focused': {
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF',
          },
          '&:disabled, &.disabled': {
            borderColor: color.common.transparent,
            color: color.gray[300],
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'text', color: 'primary' },
        style: {
          ...textVariantStyles,
          color: color.primary[700],
          '&:hover, &.hovered': {
            color: color.primary[800],
          },
          '&:focus, &.focused': {
            color: color.primary[700],
          },
          '&:disabled, &.disabled': {
            color: color.gray[300],
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'text', color: 'secondary' },
        style: {
          ...textVariantStyles,
          color: color.gray[500],
          '&:hover, &.hovered': {
            color: color.gray[600],
          },
          '&:focus, &.focused': {
            color: color.gray[500],
          },
          '&:disabled, &.disabled': {
            color: color.gray[300],
            pointerEvents: 'none',
          },
        },
      },
      {
        props: { variant: 'text', color: 'inherit' },
        style: {
          ...textVariantStyles,
          color: color.common.white,
          '&:hover, &.hovered': {
            color: color.gray[300],
          },
          '&:focus, &.focused': {
            color: color.common.white,
          },
          '&:disabled, &.disabled': {
            color: color.gray[300],
            pointerEvents: 'none',
          },
        },
      },
    ],
  },
};

export const lightButton = {
  ...themeVariants,
};

export const darkButton = {
  ...themeVariants,
};
