import { objectEntries, valueof } from 'utils/typeUtils';

import type { DefaultTheme } from 'styled-components';

export const color = {
  common: { white: '#fff', black: '#000', transparent: 'transparent' },
  gray: {
    25: '#FCFCFD',
    50: '#F9FAFB',
    100: '#F2F4F7',
    200: '#EAECF0',
    300: '#D0D5DD',
    400: '#98A2B3',
    500: '#667085',
    600: '#475467',
    700: '#344054',
    800: '#1D2939',
    900: '#101828',
  },
  primary: {
    25: '#F2FAFC',
    50: '#e1f5fb',
    100: '#b2e5f6',
    200: '#80d3f0',
    300: '#50c2ea',
    400: '#29b5e8',
    500: '#00a8e5',
    600: '#009ad7',
    700: '#0088c4',
    800: '#0077b0',
    900: '#11577f',
  },
  error: {
    25: '#FFFBFA',
    50: '#FEF3F2',
    100: '#FEE4E2',
    200: '#FECDCA',
    300: '#FDA29B',
    400: '#F97066',
    500: '#F04438',
    600: '#D92D20',
    700: '#B42318',
    800: '#912018',
    900: '#7A271A',
  },
  warning: {
    25: '#FFFCF5',
    50: '#FFFAEB',
    100: '#FEF0C7',
    200: '#FEDF89',
    300: '#FEC84B',
    400: '#FDB022',
    500: '#F79009',
    600: '#DC6803',
    700: '#B54708',
    800: '#93370D',
    900: '#7A2E0E',
  },
  success: {
    25: '#F6FEF9',
    50: '#ECFDF3',
    100: '#D1FADF',
    200: '#A6F4C5',
    300: '#6CE9A6',
    400: '#32D583',
    500: '#12B76A',
    600: '#039855',
    700: '#027A48',
    800: '#05603A',
    900: '#054F31',
  },
  starBlue: {
    25: '#F0FBFC',
    50: '#ddf4f7',
    100: '#aae5e9',
    200: '#71d3dc',
    300: '#2fc1ce',
    400: '#00b5c5',
    500: '#00a8bd',
    600: '#0099ab',
    700: '#008592',
    800: '#00717b',
    900: '#004f51',
  },
  valenciaOrange: {
    25: '#FFFBF5',
    50: '#fff4e3',
    100: '#ffe1b9',
    200: '#ffce8d',
    300: '#ffba61',
    400: '#ffab45',
    500: '#ff9e36',
    600: '#fa9233',
    700: '#f38330',
    800: '#ec752d',
    900: '#e05e2a',
  },
  purpleMoon: {
    25: '#F7F2FC',
    50: '#eee5f8',
    100: '#d2bfed',
    200: '#b494e2',
    300: '#9667d7',
    400: '#7e44cf',
    500: '#651cc6',
    600: '#5b16c0',
    700: '#4c0ab8',
    800: '#3c01b1',
    900: '#2000a3',
  },
  firstLight: {
    25: '#FAF0F4',
    50: '#f6e2eb',
    100: '#ebb7ce',
    200: '#df89af',
    300: '#d45b8f',
    400: '#cd3a78',
    500: '#c81062',
    600: '#b8115e',
    700: '#a31157',
    800: '#8e0f51',
    900: '#6a0b46',
  },
  neutral: {
    25: '#F7F7F7',
    50: '#ededed',
    100: '#D8DADB',
    200: '#adb5b8',
    300: '#8a999e',
    400: '#71848a',
    500: '#577078',
    600: '#4c6168',
    700: '#3e4e53',
    800: '#303b3f',
    900: '#202729',
  },
  iceberg: {
    25: '#EBF9FF',
    50: '#d7f3ff',
    100: '#d7f3ff',
    200: '#98c2d5',
    300: '#76a8be',
    400: '#5c94ab',
    500: '#5c94ab',
    600: '#337188',
    700: '#245d72',
    800: '#164a5d',
    900: '#003545',
  },
  rose: {
    25: '#FFF5F6',
    50: '#FFF1F3',
    100: '#FFE4E8',
    200: '#FECDD6',
    300: '#FEA3B4',
    400: '#FD6F8E',
    500: '#F63D68',
    600: '#E31B54',
    700: '#C01048',
    800: '#A11043',
    900: '#89123E',
  },
  rubySky: {
    25: '#F5EDF3',
    50: '#efe1eb',
    100: '#d8b4cf',
    200: '#be84b0',
    300: '#a35691',
    400: '#90347d',
    500: '#7c1169',
    600: '#720c65',
    700: '#63055d',
    800: '#550255',
    900: '#3c0045',
  },
  violetDawn: {
    25: '#F3F2F7',
    50: '#e5e4ee',
    100: '#bfbcd6',
    200: '#9691ba',
    300: '#6f679e',
    400: '#55498b',
    500: '#3d2b78',
    600: '#382470',
    700: '#301b66',
    800: '#28115a',
    900: '#1c0045',
  },
  winter: {
    25: '#F5F7FA',
    50: '#F5F7FA',
    100: '#ccd8e2',
    200: '#aebecb',
    300: '#8fa4b5',
    400: '#7890a3',
    500: '#607d92',
    600: '#536e81',
    700: '#445a6a',
    800: '#364755',
    900: '#24323d',
  },
} as const;

export const globalValues = ['inherit', 'initial', 'revert', 'revert-layer', 'unset'] as const;
export type GlobalValues = (typeof globalValues)[number];

export type ColorObject = typeof color;

type GetColorOptions<T> = {
  readonly [P in keyof T]: keyof T[P];
};

export type ColorOptions = GetColorOptions<ColorObject>;

type GetColorList<T> = {
  readonly [P in keyof T]: `${P extends number | string ? P : never}-${keyof T[P] extends string | number
    ? keyof T[P]
    : never}`;
}[keyof T];

export type ColorList = GetColorList<ColorObject> | GlobalValues;
export type ColorDefinition = keyof ColorObject;
export const colorListArray = objectEntries(color)
  .map(([colorName, colorValues]) => Object.keys(colorValues).map(value => `${colorName}-${value}`))
  .flat() as ColorList[];

export interface LightAndDark {
  light: ColorList;
  dark: ColorList;
}

type Common = ColorOptions['common'];
type Others = valueof<Omit<ColorOptions, 'common'>>;
type ColorArray = [ColorDefinition, Common | Others];

export type GetColorDefinition = ColorList | LightAndDark;

export const getColor = (palette: DefaultTheme['palette'], colors: GetColorDefinition) => {
  if (!colors) {
    return;
  }

  if (typeof colors === 'string' && globalValues.some(value => value === colors)) {
    return colors;
  }
  const [colorKey, colorValue] =
    typeof colors === 'string' ? (colors.split('-') as ColorArray) : (colors[palette.mode].split('-') as ColorArray);
  const subPalette = palette[colorKey];

  return 'white' in subPalette ? subPalette[colorValue as Common] : subPalette[colorValue as Others];
};

export default color;
