/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useEffect, useState } from 'react';

import type { Dispatch, ReactNode, SetStateAction } from 'react';

interface FaqSearchContextProps {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const FaqSearchContext = createContext<FaqSearchContextProps>({
  searchTerm: '',
  setSearchTerm: () => {},
});

interface FaqSearchProviderProps {
  children: ReactNode;
}

export const FaqSearchProvider: React.FC<FaqSearchProviderProps> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      if (typeof window !== 'undefined') {
        const params = new URLSearchParams(window.location.search);
        if (searchTerm) {
          params.set('search', searchTerm);
          window.history.replaceState({}, '', `${window.location.pathname}?${params}`);
        } else {
          params.delete('search');
          window.history.replaceState({}, '', `${window.location.pathname}`);
        }
      }
    }
  }, [searchTerm, isInitialized]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const params = new URLSearchParams(window.location.search);
      const searchQuery = params.get('search') || '';
      setSearchTerm(searchQuery);
      setIsInitialized(true);
    }
  }, []);

  return (
    <FaqSearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </FaqSearchContext.Provider>
  );
};

export default FaqSearchContext;
