import { createGlobalStyle } from 'styled-components';
import 'assets/fonts/font-styles.css';

export default createGlobalStyle`
html {
  scroll-behavior: smooth;
  font-size: 100%;
}
html,
body,
* {
  font-family: ${({ theme }) => theme.typography.fontFamily};
  box-sizing: border-box;
  margin: 0;
}
`;
