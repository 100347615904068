import jwt_decode from 'jwt-decode';
import { Dispatch, FC, SetStateAction, createContext, useEffect, useState } from 'react';

export const AuthContext = createContext<[boolean, Dispatch<SetStateAction<boolean>>]>([false, () => null]);

export const UserContext = createContext<[unknown, Dispatch<SetStateAction<unknown>>]>([null, () => null]);

export const AuthProvider: FC = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<unknown>();

  useEffect(() => {
    const cookie = document.cookie.split(';').find(c => c.trim().startsWith('edx-jwt-cookie-header-payload'));
    if (cookie) {
      const token = cookie.split('=')[1];
      const decoded = jwt_decode(token);

      if (decoded) {
        const { exp } = decoded as { exp: number };
        const now = new Date().getTime() / 1000;
        if (exp >= now) {
          setIsAuthenticated(true);
          setUser(decoded);
        }
      }
    }
  }, []);

  return (
    <AuthContext.Provider value={[isAuthenticated, setIsAuthenticated]}>
      <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>
    </AuthContext.Provider>
  );
};
